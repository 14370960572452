<div class="date-range">
  <div class="date-arrows">
    <mat-icon class="cavo-mat-icon" (click)="previousDateRange()">keyboard_arrow_left</mat-icon>
  </div>
  <div class="cavo-date-picker">
    <div class="start-date" style="width: 164px;">
      <mat-form-field class="cavo-outline-input cavo-mat-datepicker" appearance="outline">
        <mat-label>From</mat-label>
        <input
          #startDate
          matInput
          [matDatepicker]="startpicker"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="dateRange.start"
          (click)="startpicker.open()"
          (dateChange)="updateStartDate($event)"
          (keydown.enter)="dateUpdated(); startpicker.close()"
          name="startDate"
          autocomplete="off"
          placeholder="From"
          class="remove-input-shadow"
        />
        <mat-icon matSuffix class="clickable" (click)="startpicker.open()">date_range</mat-icon>
        <mat-datepicker #startpicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="date-selector end-date" style="width: 164px;">
      <mat-form-field class="cavo-outline-input cavo-mat-datepicker" appearance="outline">
        <mat-label>To</mat-label>
        <input
          #endDate
          matInput
          [matDatepicker]="endpicker"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="dateRange.end"
          name="endDate"
          (click)="endpicker.open()"
          (dateChange)="updateEndDate($event)"
          (keydown.enter)="dateUpdated(); endpicker.close()"
          placeholder="To"
          autocomplete="off"
          class="remove-input-shadow"
        />
        <mat-icon matSuffix class="clickable" (click)="endpicker.open()">date_range</mat-icon>
        <mat-datepicker #endpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="date-arrows">
    <mat-icon class="cavo-mat-icon" (click)="nextDateRange()">keyboard_arrow_right</mat-icon>
  </div>
</div>
