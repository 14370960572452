import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cavo-admin/auth';

@Component({
	selector: 'cavo-admin-callback',
	template: ''
})
export class CallbackComponent implements OnInit {
	constructor(private authService: AuthService) {}

	ngOnInit() {
    this.authService.handleAuthCallback();
  }
}
