import { Component, OnInit } from '@angular/core';
import { DateParserService } from '../../../core/services/date-parser.service';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'cavo-admin-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: []
})
export class DateRangeComponent implements OnInit {
  public bsConfig = { containerClass: 'theme-default', customTodayClass: 'custom-today', showWeekNumbers: false };
  dateRange = {
    start: DateParserService.startOfMonth(),
    end: DateParserService.endOfMonth()
  };

  constructor(private commonService: CommonService) {}

  dateUpdated() {
    this.updateStartDate(this.dateRange.start);
    this.updateEndDate(this.dateRange.end);
  }

  emitDateChangedEvent() {
    if (this.dateRange.start.isBefore(this.dateRange.end)) {
      this.commonService.dateRange$.next(this.dateRange);
    }
  }

  nextDateRange() {
    const date = Object.assign({}, this.dateRange);
    this.dateRange = null;
    this.dateRange = DateParserService.nextRange(date);
    this.emitDateChangedEvent();
  }

  ngOnInit() {
    this.emitDateChangedEvent();
  }

  previousDateRange() {
    const date = Object.assign({}, this.dateRange);
    this.dateRange = null;
    this.dateRange = DateParserService.previousRange(date);
    this.emitDateChangedEvent();
  }

  updateEndDate(date) {
    if (date.value) {
      this.dateRange.end = DateParserService.endOfDay(date.value);
      this.emitDateChangedEvent();
    }
  }

  updateStartDate(date) {
    if (date.value) {
      this.dateRange.start = DateParserService.startOfDay(date.value);
      this.emitDateChangedEvent();
    }
  }
}
