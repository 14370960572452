import { Component } from '@angular/core';
import { AuthService } from '@cavo-admin/auth';

@Component({
  selector: 'cavo-admin-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  user$ = this.authService.userProfile$;

  constructor(private authService: AuthService) { }
}
