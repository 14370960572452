import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({ selector: '[cavoAdminCopyClipboard]' })
export class CopyClipboardDirective {
	@Input() public copyClipboard: string;
	@Input() public context: string;
	@Output() public copied: EventEmitter<string> = new EventEmitter<string>();

	@HostListener('click', ['$event'])
	public onClick(event: MouseEvent): void {
		event.preventDefault();
		if (!this.copyClipboard) { return; }

		const listener = (e: ClipboardEvent) => {
			const clipboard = e.clipboardData || window['clipboardData'];
			clipboard.setData('text', this.copyClipboard.toString());
			e.preventDefault();
			this.copied.emit(this.copyClipboard);
		};

		document.addEventListener('copy', listener, false);
		document.execCommand('copy');
		document.removeEventListener('copy', listener, false);
	}
}