import { Injectable } from '@angular/core';
import * as Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

@Injectable()
export class DateParserService {
  static today = moment().startOf('day');
  static tomorrow = moment().endOf('day');
  static dateFormat = 'MM/DD/YYYY';
  separators = ['\\/', '\\-', ' '];

  constructor() {}

  static createMoment(date = Date.now()) {
    return moment.tz(date, 'America/Chicago') || moment.tz('America/Chicago');
  }

  static formatDate(date): string {
    return DateParserService.createMoment(date).format(DateParserService.dateFormat);
  }

  static nextDay(date) {
    return DateParserService.createMoment(date).add(1, 'd');
  }

  static previousDay(date) {
    return DateParserService.createMoment(date).subtract(1, 'd');
  }

  static startOfDay(date = null) {
    return DateParserService.createMoment(date).startOf('day');
  }

  static endOfDay(date = null) {
    return DateParserService.createMoment(date).endOf('day');
  }

  static startOfMonth(date?) {
    date = date || Date.now();
    return DateParserService.createMoment(date).startOf('month');
  }

  static endOfMonth(date?) {
    date = date || Date.now();
    return DateParserService.createMoment(date).endOf('month');
  }

  static daysInMonth(month, year): number {
    return new Date(year, month, 0).getDate();
  }

  static getRange(start, end): number {
    const range = moment.range(start, end);
    return range.diff('days') + 1;
  }

  static nextRange(date) {
    const start = DateParserService.createMoment(date.start);
    const end = DateParserService.createMoment(date.end);

    const range = DateParserService.getRange(start, end);
    if (start.date() === 1 && range === DateParserService.daysInMonth(end.month() + 1, end.year())) {
      start.add(1, 'M').startOf('M');
      end.add(1, 'M').endOf('M');
    } else {
      start.add(range, 'd');
      end.add(range, 'd');
    }
    return { start, end };
  }

  static previousRange(date) {
    const start = DateParserService.createMoment(date.start);
    const end = DateParserService.createMoment(date.end);

    const range = DateParserService.getRange(start, end);
    if (start.date() === 1 && range === DateParserService.daysInMonth(end.month() + 1, end.year())) {
      start.subtract(1, 'M').startOf('M');
      end.subtract(1, 'M').endOf('M');
    } else {
      start.subtract(range, 'd');
      end.subtract(range, 'd');
    }
    return { start, end };
  }
}
