import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cavo-admin/auth';

@Component({
  selector: 'cavo-admin-login',
  template: ''
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.login();
  }
}
