import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationInfoModel, CavoClientKeys, DateRange, QueueResponse, SftpDirectories, SQSQueueData } from '@cavo-admin/api-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class CommonService {
  connectionStatus = true;
  ediQueue$ = new BehaviorSubject<SQSQueueData>(null);
  ocrPriorityQueue$ = new BehaviorSubject<SQSQueueData>(null);
  ocrQueue$ = new BehaviorSubject<SQSQueueData>(null);
  clients$ = new BehaviorSubject<CavoClientKeys[]>([]);
  selectedInstance$ = new BehaviorSubject<ApplicationInfoModel>(null);
  sftpDirectories$ = new BehaviorSubject<SftpDirectories[]>([]);
  error: any = null;
  storageKey = 'cavo-admin:instance';
  dateRange$ = new BehaviorSubject<DateRange>(null);

  constructor(private httpClient: HttpClient) {}

  fetchClientList() {
    return this.httpClient
      .get<CavoClientKeys[]>('/api/auth0/clients')
      .pipe(map((response) => this.clients$.next(response.sort((a, b) => a.name.localeCompare(b.name)))));
  }

  fetchInstanceApplicationDoc(name: string) {
    this.selectedInstance$.next(null);
    this.error = null;
    return this.httpClient.get<ApplicationInfoModel>(`api/info/instance/${name}.cavo.io`).pipe(
      map((applicationDoc) => {
        let domain = applicationDoc.domain;

        // If the instance domain stored in the application document does not
        // begin with the instance name, then we modify the value of the
        // application document "domain" before proceeding. This is particularly
        // important for deploy.cavo.io, which has the instance name of "deploy"
        // but, because it uses the staging database, its application document
        // has a "domain" value of "staging.cavo.io". By modifying the "domain"
        // value to "deploy.cavo.io", we ensure all of the API calls and links
        // in this application go to the correct domain for deploy.cavo.io
        // (CAVO-918).
        //
        // This approach (both this dynamic modification as well as the data
        // stored in the database) might be something we need to revisit in the
        // future.
        if (!domain.startsWith(name)) {
          const modifiedDomain = `${name}.cavo.io`;
          console.log(`Modifying the domain found in the application document for "${name}" from "${domain}" to "${modifiedDomain}"`);
          domain = modifiedDomain;
        }

        this.selectedInstance$.next({ ...applicationDoc, name, domain });
        localStorage.setItem(this.storageKey, name);
      }),
      catchError((error) => (this.error = error))
    );
  }

  fetchSftpDirectories(host: string) {
    return this.httpClient.get<SftpDirectories[]>(`/api/sftp/${host}`).pipe(
      tap((response) => {
        this.sftpDirectories$.next(response);
      })
    );
  }

  getAllStatsForHost(host: string, dateRange: { start: any; end: any }): Observable<any> {
    return this.httpClient.get(`api/stats/${host}?to=${dateRange.end.toString()}&from=${dateRange.start.toString()}`);
  }

  getQueues() {
    return this.httpClient.get<QueueResponse>(`/api/queue`).pipe(
      tap((response) => {
        this.ediQueue$.next(response['edi-production']);
        this.ocrPriorityQueue$.next(response['ocr-production-priority']);
        this.ocrQueue$.next(response['ocr-production']);
      })
    );
  }

  instanceUpdated(instance: ApplicationInfoModel) {
    this.selectedInstance$.next(instance);
  }

  public initInternetConnectionStatusAlert() {
    window.addEventListener('offline', () => {
      this.connectionStatus = false;
      this.showOfflineAlert();
    });
    window.addEventListener('online', () => {
      this.connectionStatus = true;
    });
  }

  private showOfflineAlert() {
    setTimeout(() => {
      if (!this.connectionStatus) {
        this.showOfflineAlert();
      }
    }, 8000);
  }
}
