import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';

const listOfModules = [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, HttpClientModule, FlexLayoutModule];

const listOfComponents = [DateRangeComponent, CopyClipboardDirective];

@NgModule({
  declarations: [...listOfComponents],
  imports: [...listOfModules],
  exports: [...listOfModules, ...listOfComponents],
  providers: []
})
export class SharedModule {}
