import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cavo-admin/auth';

@Component({
  selector: 'cavo-admin-logout',
  templateUrl: './logout.component.html',
  styles: [
      `
      .wrapper {
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }

      .content {
        box-sizing: border-box;
        border-radius: 4px;
        width: 452px;
        height: 376px;
        padding: 52px 32px;
        border: 1px solid #ebebeb;
        box-shadow: 0 0 20px #dddddd;
        background: #ffffff;
        font-size: 16px;
        font-weight: 300;
        display: flex;
        text-align: center;
        flex-direction: column;
      }

      .content p {
        margin: 0;
      }

			.cavo-logo {
				margin-bottom: 20px;
			}

			h2 {
				font-size: 22px;
				font-weight: 600;
				padding-bottom: 22px;
				margin: 0;
			}

			button {
				width: 180px;
				margin: 48px auto;
			}

			.helper-footer {
				font-size: 14px;
			}
		`
	]
})
export class LogoutComponent implements OnInit {
	constructor(private authService: AuthService) {}

	ngOnInit() {
		Object.keys(localStorage)
			.filter(key => key !== 'url')
			.forEach(key => {
				localStorage.removeItem(key);
			});
	}

	signIn() {
		this.authService.login();
	}
}
