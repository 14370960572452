import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesList } from '../core/constants/routes.constants';

@Component({
	selector: 'cavo-blocked',
	templateUrl: './blocked.component.html',
	styles: [
		`
			.wrapper-blocked {
				height: 100vh;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 16px;
			}

			.wrapper-blocked p {
				margin-bottom: 30px;
			}

			.wrapper-blocked img {
				width: 108px;
				display: inline-block;
				padding-bottom: 40px;
			}

			.wrapper-blocked .content {
				width: 470px;
				margin: 0 auto;
				padding: 40px 50px;
				background-color: #fff;
				box-shadow: 0 0 20px #efefef;
				border-radius: 5px;
				border: 1px solid #efefef;
			}
		`
	]
})
export class BlockedComponent {
	constructor(private router: Router) {}

	public goToLogin() {
		this.router.navigate([RoutesList.LOGIN]);
	}
}
