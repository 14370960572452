import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0UserProfile, AuthOptions, Management, WebAuth } from 'auth0-js';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, shareReplay, tap } from 'rxjs/operators';
import { CavoAppMetadata } from '@cavo-admin/api-interfaces';
import { User } from 'auth0';
import { HttpClient } from '@angular/common/http';

const DOMAIN = 'adventhp.auth0.com';
const TREND_CONNECTION = 'trendhealthpartners-ad';
const AUTH0_BASE_OPTIONS: AuthOptions = {
  domain: DOMAIN,
  redirectUri: `${location.origin}/callback`,
  clientID: 'f4ADK6ZVQOeksZA56QGlRhyfz4Y6kHGE',
  audience: 'https://adventhp.auth0.com/api/v2/',
  responseType: 'token',
  scope: 'openid profile, read:current_user'
};
const ignore = ['login', 'callback', 'blocked', 'logout', 'reset-password'];

@Injectable({ providedIn: 'root' })
export class AuthService {
  userProfile$ = new BehaviorSubject<User<CavoAppMetadata>>(null);
  private managementClient: Management;
  private readonly webAuth = new WebAuth(AUTH0_BASE_OPTIONS);

  constructor(private router: Router, private httpClient: HttpClient) {
    this.setNonce();
    this.localAuthSetup();
  }

  getAccessToken(): Observable<string> {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && !this.managementClient) {
      this.managementClient = new Management({ domain: DOMAIN, token: accessToken });
    }
    return of(accessToken);
  }

  getTokenInfo() {
    return this.getAccessToken().pipe(
      filter(accessToken => accessToken !== null),
      concatMap(accessToken => this.parseUserInfo(accessToken)),
      shareReplay(1)
    );
  }

  getUser(userId: string): Observable<Auth0UserProfile> {
    return (new Observable(subscriber => {
      this.managementClient.getUser(userId, (error, user) => {
        if (error) {
          subscriber.error(error);
        } else {
          this.userProfile$.next(user);
          subscriber.next(user);
          subscriber.complete();
        }
      });
    }) as Observable<Auth0UserProfile>).pipe(
      shareReplay(1),
      catchError(error => throwError(error))
    );
  }

  goToInstanceWithToken(instance, facility, encounter, auditId) {
    // Runtime input validation: Ensure all inputs are non-empty strings:
    ensureNonEmptyString('instance', instance);
    ensureNonEmptyString('facility', facility);
    ensureNonEmptyString('encounter', encounter);
    ensureNonEmptyString('auditId', auditId);

    return this.httpClient.get(`api/auth-tokens/${instance}/${facility}/${encounter}/${auditId}`);
  }

  goToInstance(hostname: string) {
    // Go to Cavo login page, requesting that Cavo authenticate using Advent's
    // SAML connection so that Advent staff can login to the instance.
    window.location.href = `https://${hostname}/login?user_accounts_connection=${TREND_CONNECTION}`;
  }


  handleAuthCallback() {
    this.webAuth.parseHash({ nonce: localStorage.getItem('nonce'), state: localStorage.getItem('state') }, (error, result) => {
      if (result.accessToken) {
        this.managementClient = new Management({ domain: AUTH0_BASE_OPTIONS.domain, token: result.accessToken });
        localStorage.setItem('access_token', result.accessToken);
        this.parseUserInfo(result.accessToken)
          .pipe(
            concatMap(profile => this.getUser(profile.sub)),
            tap(() => this.router.navigate(['/home']))
          )
          .subscribe();
      }
    });
  }

  isAuthenticated() {
    return localStorage.getItem('access_token') !== null;
  }

  login(): void {
    this.setNonce();
    this.webAuth.authorize({
      connection: TREND_CONNECTION,
      nonce: localStorage.getItem('nonce'),
      state: localStorage.getItem('state')
    });
  }

  logout() {
    this.webAuth.logout({ returnTo: `${location.origin}/logout` });
  }

  parseUserInfo(token): Observable<Auth0UserProfile> {
    return new Observable(subscriber => {
      this.webAuth.client.userInfo(token, (error, profile) => {
        if (error) {
          subscriber.error(error);
        } else {
          subscriber.next(profile);
          subscriber.complete();
        }
      });
    });
  }

  setNonce() {
    const keys = ['nonce', 'state'];
    keys.forEach(key => {
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, this.generateNonce());
      }
    });
  }

  /**
   * See {@link https://auth0.com/docs/api-auth/tutorials/nonce|Auth0 Nonce}
   * @param {number} length
   * @returns {string} Cryptographically secure random string
   */
  private generateNonce(length = 16): string {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
    let result = '';

    while (length > 0) {
      const bytes = new Uint8Array(16);
      const random = window.crypto.getRandomValues(bytes);

      random.forEach(function(c) {
        if (length === 0) {
          return;
        }
        if (c < charset.length) {
          result += charset[c];
          length--;
        }
      });
    }
    return result;
  }

  private localAuthSetup() {
    this.getTokenInfo()
      .pipe(concatMap(profile => this.getUser(profile.sub)))
      .subscribe();
  }
}

function ensureNonEmptyString(name, value) {
  if (typeof(value) !== 'string' || value === '') {
    throw new Error(`${name} must be a non-empty string`);
  }
}
